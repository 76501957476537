<template>
  <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
    Loading assessment
  </loading-spinner>
  <div
    v-else-if="!isLoading && !assessment"
    class="bg-white border p-3 shadow-sm text-muted text-center fst-italic"
  >
    <p>No assessment found</p>
    <button class="btn btn-sm btn-primary px-3" @click="$emit('close')">
      <i class="fas fa-arrow-left me-2" />
      Back
    </button>
  </div>
  <div class="position-relative" v-else-if="!isLoading && assessment">
    <div
      class="d-flex justify-content-start align-items-stretch rounded border lh-1 shadow-sm overflow-hidden"
    >
      <div class="p-3 fw-medium bg-disabled flex-shrink-0 flex-grow-0">
        ASSESSMENT
      </div>
      <div
        class="ps-3 pe-2 py-2 bg-white flex-fill d-flex justify-content-between align-items-center"
      >
        <span>{{ assessment.title }}</span>
        <button class="btn btn-sm btn-danger px-3" @click="cancel()">
          <i class="fas fa-arrow-left me-2" />
          <span v-if="dependantAnswers">Back</span>
          <span v-else>Cancel</span>
        </button>
      </div>
    </div>
    <div class="py-3">
      <span class="badge bg-dark py-2 fw-normal">
        Total Questions: {{ assessment.questions.length }}
      </span>
    </div>
    <div
      class="bg-white border rounded shadow-sm px-3 px-sm-5 px-md-3 px-lg-5"
      v-for="(question, index) in assessment.questions"
      :key="question._id"
    >
      <div class="pt-3 pb-2">
        <small class="text-dark fw-medium">Q{{ index + 1 }}</small>
        <i class="fas fa-circle mx-2 align-middle" style="font-size: 0.2rem" />
        <small class="text-muted fw-medium">{{ question.section }}</small>
        <br />
        {{ question.text }}
      </div>
      <div class="py-3 text-center" v-if="question.media">
        <img
          :src="Helper.formatMediaUrl(question.media)"
          class="border shadow-sm clickable"
          style="max-height: 300px"
          @click.stop="currentMedia = question.media"
        />
      </div>
      <p class="mb-0 mt-3 fw-medium small">Select correct answer</p>
      <div class="pt-2">
        <div
          v-for="answer in question.answers"
          :key="answer._id"
          class="mb-3 border rounded d-flex justify-content-start align-items-stretch overflow-hidden"
          :class="answerClass(question._id, answer._id, answer.sequence)"
          @click="selectAnswer(question._id, answer._id, answer.sequence)"
        >
          <div
            class="text-uppercase text-center bg-light border-end px-3 py-2 flex-shrink-0 flex-grow-0"
            style="width: 50px"
          >
            {{ answer.sequence }}
          </div>
          <div class="px-3 py-2 flex-fill">
            {{ answer.text }}
          </div>
          <div
            class="px-3 py-2"
            :class="{
              invisible: !checkSubmittedAnswer(question._id, answer._id),
            }"
          >
            <i class="fas fa-check text-success" />
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-light border-top pt-3"
      style="position: sticky; bottom: -0.3rem; z-index: 1020"
    >
      <div
        class="p-3 bg-primary text-light border shadow-sm rounded-3 d-flex justify-content-between align-items-center position-relative"
      >
        <div v-if="!dependantAnswers">
          <span
            class="badge border fs-6 fw-normal"
            :class="
              answeredCount !== assessment.questions.length
                ? 'bg-danger'
                : 'bg-success'
            "
            style="width: 40px"
          >
            {{ answeredCount }}
          </span>
          /
          <span
            class="badge bg-dark border text-light fs-6 fw-normal"
            style="width: 40px"
          >
            {{ assessment.questions.length }}
          </span>
        </div>
        <div v-else>
          <span
            class="badge border fs-6 fw-normal"
            :class="dependantMarks < 80 ? 'bg-danger' : 'bg-success'"
            style="width: 46px"
          >
            {{ dependantMarks }}%
          </span>
        </div>
        <button
          class="btn p-0 border rounded-circle position-absolute top-50 start-50 translate-middle text-light"
          style="height: 28px; width: 28px"
          title="Scroll to top"
          @click="scrollToTop()"
        >
          <i class="fas fa-chevron-up" />
        </button>
        <button
          class="btn btn-success px-4"
          @click="submit()"
          v-if="!dependantAnswers"
        >
          Submit
        </button>
      </div>
    </div>
    <media-viewer
      v-if="currentMedia"
      :url="this.Helper.formatMediaUrl(currentMedia)"
      @close="currentMedia = null"
    />
    <loading-spinner
      class="position-fixed text-light py-5"
      lockedElement="body"
      style="z-index: 1031"
      v-if="isSubmitting"
    >
      Processing marks...
    </loading-spinner>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import LoadingSpinner from "@/components/LoadingSpinner";
import MediaViewer from "@/components/Modals/MediaViewer";

export default {
  props: ["assessmentId", "dependantId", "type", "minMarks"],
  components: {
    MediaViewer,
    LoadingSpinner,
  },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      assessment: null,
      dependantAnswers: null,
      dependantMarks: 0,
      currentMedia: null,
      models: [],
    };
  },
  computed: {
    answeredCount() {
      return this.models.filter((m) => m.answerId).length;
    },
    checkSubmittedAnswer() {
      return (questionId, answerId) => {
        if (this.dependantAnswers) return false;

        let model = this.models.find((m) => m.questionId == questionId);

        if (model && model.answerId == answerId) {
          return true;
        } else {
          return false;
        }
      };
    },
    answerClass() {
      return (questionId, answerId) => {
        if (this.dependantAnswers) {
          let model = this.models.find((m) => m.questionId == questionId);

          const question = this.assessment.questions.find(
            (q) => q._id == questionId
          );

          let correctAnswerId = question?.answers.find(
            (a) => a.sequence == question?.correct_answer
          )?._id;

          if (
            model &&
            model.answerId == answerId &&
            correctAnswerId == answerId
          ) {
            return "border-success bg-success-opacity";
          } else if (
            model &&
            model.answerId == answerId &&
            correctAnswerId != answerId
          ) {
            return "border-danger bg-danger-opacity";
          } else if (answerId == correctAnswerId) {
            return "border-success text-success";
          } else {
            return;
          }
        } else {
          return this.checkSubmittedAnswer(questionId, answerId)
            ? "border-success bg-success-opacity cursor-pointer"
            : "clickable cursor-pointer";
        }
      };
    },
  },
  methods: {
    cancel() {
      if (this.dependantAnswers) {
        this.$emit("close");
      } else {
        Swal.fire({
          icon: "question",
          title: "Anda pasti untuk batal?",
          html: "Jika anda batal sekarang, semua jawapan tidak akan dihantar.",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          confirmButtonColor: "red",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$emit("close");
          }
        });
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    selectAnswer(questionId, answerId, answerSequence) {
      if (this.dependantAnswers) return;

      let model = this.models.find((m) => m.questionId == questionId);

      if (questionId) {
        if (model.answerId == answerId) {
          model.answerId = null;
          model.answerSequence = null;
        } else {
          model.answerId = answerId;
          model.answerSequence = answerSequence;
        }
      }
    },
    getAssessment() {
      this.isLoading = true;

      return new Promise(async (resolve) => {
        try {
          const id = JSON.parse(this.assessmentId).id;

          let url = "assessments";

          if (this.type && this.type == "tutor") {
            url = "assessments";
          }

          const { data } = await this.API.get(`${url}/${id}`);

          this.assessment = data;

          this.models = JSON.parse(
            JSON.stringify(
              data.questions.map((q) => {
                return {
                  questionId: q._id,
                  answerId: null,
                  answerSequence: null,
                };
              })
            )
          );

          if (!this.dependantAnswers) {
            Swal.fire({
              icon: "info",
              title: "<h5 class='mb-0'>Sebelum anda menjawab</h5>",
              html: `
            <p class='small bg-light rounded border shadow-sm p-3 text-dark'>
              Markah lulus adalah <span class='fw-medium'>${
                this.minMarks || 80
              }%</span><br /><br />
              Jika markah anda kurang daripada <span class='fw-medium'>${
                this.minMarks || 80
              }%</span>, anda boleh mengambil semula kemudian<br /><br />
              <span class='fs-5 fw-medium text-primary'>SEMOGA BERJAYA!</span>
            </p>
            <p class='small text-muted'>
              Nota: Jika markah ambil semula rendah daripada markah sebelumnya, ia tidak akan disimpan
            </p>
            <p class='mb-0 small text-muted'>
              P/S: Jangan 'refresh' laman ini atau klik butang kembali, jawapan anda tidak akan dihantar
            </p>`,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          } else {
            this.models = this.models.map((model) => {
              model.answerId = this.dependantAnswers.find(
                (da) => da.questionId == model.questionId
              )?.answerId;

              model.answerSequence = this.dependantAnswers.find(
                (da) => da.questionId == model.questionId
              )?.answerSequence;

              return model;
            });
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;

          resolve();
        }
      });
    },
    submit() {
      if (this.answeredCount !== this.assessment.questions.length) {
        Swal.fire({
          icon: "error",
          title: "<h5 class='mb-0'>Invalid submission</h5>",
          html: "<p class='small bg-light rounded border shadow-sm p-3 mb-0 text-dark'>You have to answer all questions</p>",
        });
      } else {
        Swal.fire({
          icon: "question",
          title: "<h5 class='mb-0'>Submit assessment?</h5>",
          showCancelButton: true,
          cancelButtonText: "Belum Lagi",
          confirmButtonText: "Ya, saya dah siap",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.isSubmitting = true;

            let body = {
              answers: this.models,
            };

            if (this.type && this.type == "tutor") {
              body.assessment = this.assessment._id;

              body.user = this.dependantId;
            } else {
              body.assessment = this.assessment._id;

              body.dependant = this.dependantId;
            }

            let method = "post";

            let url =
              this.type && this.type == "tutor"
                ? "teacher-assessments"
                : "dependant-assessments";

            if (JSON.parse(this.assessmentId).dependantAssessment) {
              method = "put";

              url += `/${JSON.parse(this.assessmentId).dependantAssessment}`;
            }

            try {
              const { data } = await this.API[method](url, body);

              let response = {};

              if (data) {
                response = JSON.parse(JSON.stringify(data));

                if (this.type && this.type == "tutor") {
                  response.assessment = response.assessment._id;

                  response.user = response.user._id;
                } else {
                  response.assessment = response.assessment._id;

                  response.dependant = response.dependant._id;
                }
              }

              this.isSubmitting = false;

              let resultText = "";

              if (response.marks && response.marks >= (this.minMarks || 80)) {
                resultText = "TAHNIAH! ANDA TELAH BUAT YANG TERBAIK 😍";
              } else {
                resultText =
                  "USAHA YANG BAGUS, TAPI ANDA BOLEH BUAT LEBIH BAIK! JAWAB SEMULA PENILAIAN UNTUK MEMPERBAIKI MARKAH ANDA 😉";
              }

              Swal.fire(
                "<h5 class='mb-0'>Assessment submitted</h5>",
                `
              <p class="small">Markah anda adalah:</p>
              <div class="pb-3">
                <span class="fw-medium py-2 px-3 rounded w-auto ${
                  response.marks && response.marks >= (this.minMarks || 80)
                    ? "bg-success text-light"
                    : "bg-warning text-dark"
                }">${response.marks}%</span>
              </div>
              <p class="small fw-medium">${resultText}</p>
              `,
                "success"
              ).then(() => {
                this.$emit("close", {
                  data: response,
                });
              });
            } catch (error) {
              this.isSubmitting = false;

              Swal.fire(
                "<h5 class='mb-0'>Penilaian tidak dihantar</h5>",
                `
                <p class="small">Error:</p>
                <div class="pb-3">
                  ${error}
                </div>
                `,
                "error"
              );
            }
          }
        });
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    if (this.assessmentId && JSON.parse(this.assessmentId).id) {
      this.dependantAnswers = JSON.parse(this.assessmentId).assessmentResult;

      this.dependantMarks = JSON.parse(this.assessmentId).assessmentMarks || 0;

      this.getAssessment();
    }
  },
};
</script>